import React, { useEffect, useState } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import style from "./style.module.less"
import SelectSelf from "@/components/Air/SelectSelf"
import Modal from "@/components/Air/Modal"
import useBigScreen from "@/hooks/bigScreen"
import useOptions from "@/hooks/useOptions"
import Axios from "axios"


const Index = () => {
    const [allData, setAllData] = useState([])
    const [areaSelectArr, setAreaSelect] = useState(['地域', "すべて"])
    const [citySelectArr, setCitySelect] = useState(['都道府県', "すべて"])
    const [changeAll, setChangeOpt] = useState(0)
    const [selectAll, setSelectAll] = useState(true)
    const [ifPhone, setIfphone] = useState(true);
    const [selectData, setSelectData] = useState([])
    const pcOrnot = useBigScreen(600);
    const [firstSelect, setFirst] = useState('地域')
    const [ifSecondAll, setSecondAll] = useState(true)

    const content = ['*本取り組みの開始時期、終了時期は店舗ごとに異なります。詳細は各店舗へお問い合わせください。', '*展示店舗は予告なく変更する場合がございます。最新状況は各店舗にお問い合わせください。']


    //判断是移动端还是pc端
    useEffect(
        function () {
            if (pcOrnot) {
                setIfphone(false)
            } else {
                setIfphone(true)
            }
        },
        [pcOrnot]
    )
    // 获取服务器上的全部地址信息.
    useEffect(() => {
        getAddress()
    }, [])

    const getAddress = () => {
        Axios.get(`${process.env.resourceUrl}docs/air-store/jp.json`).then(function (data) {
            let arrArea = useOptions(data.data, 'area')
            // let arrCity = useOptions(data , 'prefectures')
            setAreaSelect(arrArea)
            setAllData(data.data)
            setSelectData(data.data)

        })
    }
    //获取第一个select框的选项
    const getOptionOne = (num, option) => {
        setChangeOpt(1)
        if (!(num == 1 || 0)) {

            setSelectAll(false);
            getCityArr(option.innerHTML)

            let arr = allData.filter((item) => item.area == option.innerHTML)
            setSelectData(arr)

        } else {
            setSelectAll(true)
            setSelectData(allData)

        }
        setFirst(option.innerHTML)
        setSecondAll(true)
        // getAddressMsg(option.innerHTML)
    }
    //获取第二个框的选项
    const getOptionTwo = (num, option) => {

        setChangeOpt(0)
        if (option.innerHTML == 'すべて') {
            let arr = allData.filter((item) => item.area == firstSelect)
            setSelectData(arr)
            setSecondAll(true)
        } else {
            let arr = allData.filter((item) => item.prefectures == option.innerHTML)
            setSelectData(arr)
            setSecondAll(false)

        }

    }
    //获取都道府县的json
    const getCityArr = (option) => {
        let arr = allData.filter((item) => item.area == option)
        arr = useOptions(arr, 'prefectures')
        setCitySelect(arr)
    }

    const addressPreRender = (arr, everyPre) => {
        let renderArr = arr.filter(value => value.prefectures == everyPre);
        return (
            <div className={style.countryBox}>
                {renderArr.map(item => {
                    return (
                        <div><a href={item.storeLink} target="_blank">
                            {item.storeName}
                        </a></div>
                    )
                })}
            </div>
        )
    }
    const addressAllRender = (item) => {
        let renderArr = allData.filter(value => value.area == item);
        let prefecturesArr = useOptions(renderArr, 'prefectures');

        return (
            <div>
                {prefecturesArr.map((everyPre) => {
                    return (
                        <div>
                            <h2>{everyPre}</h2>
                            {addressPreRender(renderArr, everyPre)}
                        </div>
                    )
                })}
            </div>
        )

    }


    return (
        <Layout menuBlack={true}>
            <SEO page="Air_Stores" />
            <div className={style.header} />
            {ifPhone ? <Modal content={content} /> : ""}
            <div className={style.title}>都道府県で検索</div>
            <div className={style.selectBody}>
                {/* 筛选框部分 */}
                <div className={style.selectBox} >
                    <SelectSelf
                        options={areaSelectArr}
                        ifPhone={ifPhone}
                        name="地域"
                        optionNum={getOptionOne}
                    />

                    <SelectSelf
                        ifChange={changeAll}
                        options={citySelectArr}
                        ifPhone={ifPhone}
                        name="都道府県"
                        disabled={selectAll}
                        optionNum={getOptionTwo}
                    />
                </div>
                {!ifPhone && (
                    <div className={style.hint}>
                        <div>*本取り組みの開始時期、終了時期は店舗ごとに異なります。詳細は各店舗へお問い合わせください。</div>
                        <div>*展示店舗は予告なく変更する場合がございます。最新状況は各店舗にお問い合わせください。</div>
                    </div>
                )}

                {/* 地址渲染部分 */}
                <div className={style.addressBox}>
                    {!selectAll && ifSecondAll && citySelectArr.map((item) => {
                        return (
                            <div key={item.storeName} className={style.addressItem} >
                                <h2>{item}</h2>
                                {addressPreRender(selectData, item)}

                            </div>)
                    })}
                    {!selectAll && !ifSecondAll && selectData.map(item => {
                        return (
                            <div key={item.storeName} className={style.addressItem} >

                                <div><a href={item.storeLink} target="_blank">
                                    {item.storeName}
                                    {item.store}</a></div>

                            </div>
                        )
                    }
                    )}
                    {selectAll && areaSelectArr.map((item) => {
                        return (
                            <div key={item.storeName} className={style.addressItem} >
                                <h1>{item}</h1>
                                {addressAllRender(item)}
                            </div>
                        )
                    })}




                </div>
            </div>




        </Layout>

    )
}

export default Index
