import React,{useState} from "react"
import style from "./modal.module.less"


const Modal= (props) =>{
    const [status, setStatus]=useState(true);

    return(
        <div className={style.mask} style={{display : status ? 'flex' : 'none'}}  >
            <div className={style.maskBox}>
           
                {props.content.map((item)=>{
                    return (
                        <div>{item}</div>
                    )
                })}
                <div className={style.sureBtn} onClick={()=>{setStatus(false)}}>確認</div>
            </div>
        
        </div>
    )
}
export default Modal
