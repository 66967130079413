import * as React from "react"

function useOptions(data, key) {
    let arr = [];
    let obj = {};
    for (let i = 0; i < data.length; i++) {
        let value = data[i][key];
        obj[value] = value
    }
    arr = Object.keys(obj)
    return arr
}
export default useOptions
