import React,{useRef} from "react"
import style from "./select.module.less"



const SelectSelf= (props) =>{

    const selectValue = useRef(null);
    const selectName = props.name;
    const ifPhone = props.ifPhone;
    const options = props.options;

    if(props.ifChange==1){
        selectValue.current.options[1].selected=true 
    }
     

    const selectOption = () =>{
        let selectNum=selectValue.current.options["selectedIndex"]
        props.optionNum(selectNum,selectValue.current.options[selectNum]);
        // selectValue.current.options[1].selected=true
        
        
    }

  
    return(
        <div className={style.selectOut}>
            <select 
                    ref={selectValue} 
                    disabled={props.disabled == undefined? false : props.disabled==false ? false :true}  onChange={selectOption} >

                        <option value={selectName} key='default' disabled selected >{selectName}</option>
                        <option key='all' value="all" >すべて</option>
                        {options.map((item,index) => {
                        return (
                        <option key={item} value={item}>{item}</option>) 
                            
                        })}
                        

                    </select>

        </div>
        
               
    )
}
export default SelectSelf
